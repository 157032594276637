@font-face {
  font-family: 'Guggenheim Sans';
  src: url('https://www.guggenheim.org/wp-content/themes/laura/dist/assets/GuggenheimSans-Regular-Bilb025c.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Guggenheim Sans';
  src: url('https://www.guggenheim.org/wp-content/themes/laura/dist/assets/GuggenheimSans-Bold-BxpC8Ga2.ttf');
  font-weight: 700;
  font-style: normal;
}
